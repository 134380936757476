import React from 'react';
import config from './config';

const MaintenancePage = () => {
    return (
        <React.Fragment>
            {/* Header with logo */}
            <div className="header py-3 px-4 border-bottom">
                <img
                    src={`${config.imagepath}beautilogo-new.png`}
                    alt="Beauti Logo"
                    height="40"
                />
            </div>

            {/* Main content */}
            <div className="maintenance-wrapper">
                <div className="container">
                    <div className="row justify-content-center align-items-center min-vh-100">
                        <div className="col-md-8">
                            <div className="maintenance-content text-center">
                                {/* <img 
                                    src={`${config.imagepath}beautilogo-new.png`} 
                                    alt="Beauti Logo" 
                                    className="mb-5"
                                    height="60"
                                /> */}
                                <h1 className="mb-3">Update in Progress</h1>
                                <p className="text-muted">
                                    We're currently working on some exciting updates to enhance your experience and will be back soon. Thanks for your patience. Please check back later for new features and improvements.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MaintenancePage;