import Routes from "./routes";
import { Suspense } from "react";
import Loader from "component/Loader";
import MaintenancePage from "MaintenancePage";
// import ErrorBoundary from './ErrorBoundary';
// import { Link } from "react-router-dom";
// import config from "./config";
const App = () => {
  const isDowntime = () => {
    try {
      const downtimeValue = process.env.REACT_APP_MAINTENANCE_MODE;
      // Check if the value is a string 'true' or boolean true
      return downtimeValue === 'true' || downtimeValue === true;
    } catch (error) {
      console.error("Error parsing maintenance mode:", error);
      return false; // Default to false if there's an error
    }
  };
  //Configuration for the downtime
  return (
    <>
      <Suspense fallback={<Loader />}>
      {isDowntime() ? <MaintenancePage /> : <Routes />}


      </Suspense>
      {/* <Link to={config.basename}>Login</Link> */}
      {/* <ErrorBoundary> */}
      {/* <Routes /> */}
      {/* </ErrorBoundary> */}
    </>
  );
};

export default App;
